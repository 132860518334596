<template>
  <div class="contract-download">
    <div class="button-group">
      <van-button 
        class="btn"
        round
        plain
        icon="arrow-left"
        type="default" 
        @click="goBack">返回</van-button>
      <van-button 
        class="btn"
        round
        type="primary" 
        icon="success"
        @click="confirm">确认</van-button>
    </div>
    
    <div class="file-content" v-if="fileUrl">
      <iframe :src="fileUrl" width="100%" height="750px"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractDownload',
  data() {
    return {
      fileUrl: '',
      fileName: '',
      agentconNo: ''
    }
  },
  created() {
    // 获取路由参数
    this.fileUrl = this.$route.query.fileUrl
    this.fileName = this.$route.query.fileName
    this.agentconNo = this.$route.query.agentconNo
    
    if(this.fileUrl && this.fileName) {
      this.downloadFile()
    }
  },
  methods: {
    downloadFile() {
      const link = document.createElement('a')
      link.href = this.fileUrl
      link.download = this.fileName || '合同文件'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    goBack() {
      this.$router.go(-1)
    },
    confirm() {
      // 确认事件处理
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.contract-download {
  padding: 20px;
  background: #f8f8f8;
  min-height: 100vh;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 10px;
}

.btn {
  min-width: 120px;
  height: 40px;
  font-size: 16px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.file-content {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  overflow: hidden;
}

.file-content iframe {
  border: none;
  border-radius: 4px;
}
</style>
